(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name services.factory:Season
   *
   * @description
   *
   */
  angular
  .module('neo.services')
  .factory('CurrentSeason', CurrentSeason);

  function CurrentSeason($resource, EnvironmentConfig) {
    return $resource(EnvironmentConfig.API + '/season');
  }
}());
